import React, { FunctionComponent, useCallback, useState, useEffect } from 'react';
import { Container, SEO, Typography } from '../components';
import TextField from '@material-ui/core/TextField';
import MUITypography from '@material-ui/core/Typography';
import styled from 'styled-components';
import { ObjectLiteral } from '../type';
// import { graphql } from 'gatsby';

type FundPagePropsType = {
    data: any;
};

export const Results = styled.div`
    margin-top: 2rem;
    display: flex;
`;

export const Categories = styled.div`
    text-align: right;
`;

export const Amounts = styled.div`
    margin-left: 1rem;
`;

export const Summary = styled(MUITypography)`
    color: #ff493d;
`;

const factors = {
    emergency: 0.1,
    investment: 0.075,
    fun: 0.05,
    travel: 0.1,
    charity: 0.01,
    education: 0.02,
};

const FundPage: FunctionComponent<FundPagePropsType> = () => {

    const [amounts, setAmounts] = useState<ObjectLiteral>({
        emergency: 0,
        investment: 0,
        fun: 0,
        travel: 0,
        charity: 0,
        education: 0,
        insurance: 0,
    });

    const [coefficient, setCoefficient] = useState(1);
    const [value, setValue] = useState(0);

    useEffect(() => {
        const actualValue = value * coefficient;
        setAmounts({
            emergency: Math.ceil(value * factors.emergency),
            investment: Math.ceil(actualValue * factors.investment),
            fun: Math.ceil(actualValue * factors.fun),
            travel: Math.ceil(actualValue * factors.travel),
            charity: Math.ceil(actualValue * factors.charity),
            education: Math.ceil(actualValue * factors.education),
            insurance: actualValue ? 35 : 0,
            cloth: actualValue ? 25 : 0,
        });
    }, [ value, coefficient ]);

    const onIncomeChange = useCallback((event) => {
        const result = parseInt(event.target.value, 10);
        if (isNaN(result) || result < 0) {
            return;
        }

        setValue(result);
    }, [ setAmounts ]);

    const onCoefficientChange = useCallback((event) => {
        const result = parseFloat(event.target.value);
        if (isNaN(result) || result < 0) {
            return;
        }

        setCoefficient(result);
    }, [ setAmounts ]);

    const part = 100 * coefficient;

    return (
        <Container>
            <SEO
                title="Фондирование доходов"
            />
            <Typography>Фондирование доходов</Typography>
            <TextField variant="outlined" placeholder="Доход, &euro;" onChange={onIncomeChange} />{' '}
            <TextField variant="outlined" placeholder="Коэффициент, 1" onChange={onCoefficientChange} />
            <Results>
                <Categories>
                    <MUITypography variant="subtitle1">Финансовая подушка: </MUITypography>
                    <MUITypography variant="subtitle1">Инвестиции: </MUITypography>
                    <MUITypography variant="subtitle1">Развлечения: </MUITypography>
                    <MUITypography variant="subtitle1">Путешествия: </MUITypography>
                    <MUITypography variant="subtitle1">Благотворительность: </MUITypography>
                    <MUITypography variant="subtitle1">Самообразование: </MUITypography>
                    <MUITypography variant="subtitle1">Страховка: </MUITypography>
                    <MUITypography variant="subtitle1">Одежда: </MUITypography>
                    <Summary variant="subtitle1">Итого: </Summary>
                </Categories>
                <Amounts>
                    <MUITypography variant="subtitle1">{amounts.emergency} &euro; ({factors.emergency * 100}%)</MUITypography>
                    <MUITypography variant="subtitle1">{amounts.investment} &euro; ({factors.investment * part}%)</MUITypography>
                    <MUITypography variant="subtitle1">{amounts.fun} &euro; ({factors.fun * part}%)</MUITypography>
                    <MUITypography variant="subtitle1">{amounts.travel} &euro; ({factors.travel * part}%)</MUITypography>
                    <MUITypography variant="subtitle1">{amounts.charity} &euro; ({factors.charity * part}%)</MUITypography>
                    <MUITypography variant="subtitle1">{amounts.education} &euro; ({factors.education * part}%)</MUITypography>
                    <MUITypography variant="subtitle1">{amounts.insurance} &euro;</MUITypography>
                    <MUITypography variant="subtitle1">{amounts.cloth} &euro;</MUITypography>
                    <Summary variant="subtitle1">{Object.values(amounts).reduce((result, amount) => result + amount, 0)} &euro;</Summary>
                </Amounts>
            </Results>
        </Container>
    );
};

// export const pageQuery = graphql`
//     query HomePageQuery {
//     }
// `;

export default FundPage;
